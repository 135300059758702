<template>
  <div>

    <!-- <v-container fill-height fluid class="mt-2">
      <v-row align="center" justify="center">
        <v-col>
          <v-list-item-avatar size="160">
            <v-img src="assets/hesymbol.png"></v-img>
          </v-list-item-avatar>
        </v-col>
      </v-row>
    </v-container> -->

    <v-divider class="mt-4 mx-6"></v-divider>

    <v-card elevation="4" class="mx-6 mt-0 pt-0">
      <v-toolbar color="blue darken-3" dark flat dense>
        <v-toolbar-title>{{ $t('tables.accountDetails') }}</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <v-container class="align-center text-center mx-4">
      <v-row>
        <!--USER INFORMATION-->

        <v-col>
          <v-data-iterator
            :items="userInfo"
            :items-per-page="1"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.firstName"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="11"
                >
                  <v-card>
                    <v-card-title class="subheading font-weight-bold">
                      {{ $t('titles.userInformation') }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>{{ $t('global.firstName') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.firstName }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.lastName') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.lastName }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.email') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.email }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.nationality') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.nationality }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.nif') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.nif }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.address') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.address }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.zipCode') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.zipCode }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.memberSince') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{
                            moment(item.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>

        <!--GROUP INFORMATION-->

        <v-col>
          <v-data-iterator
            :items="groupInfo"
            :items-per-page="1"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  class="ml-2"
                  v-for="item in props.items"
                  :key="item.name"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="11"
                >
                  <v-card>
                    <v-card-title class="subheading font-weight-bold">
                      {{ $t('titles.groupInformation') }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>{{ $t('global.name') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.name }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.subscription') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.packageName }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content
                          >{{ $t('global.feeBasicActive') }}:</v-list-item-content
                        >
                        <v-list-item-content class="align-end">
                          <v-icon color="green darken-2" v-if="item.fee"
                            >done</v-icon
                          >
                          <v-icon color="red darken-2" v-if="!item.fee"
                            >close</v-icon
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content
                          >{{ $t('global.registeredMembers') }}:</v-list-item-content
                        >
                        <v-list-item-content class="align-end">
                          {{ item.users }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.nif') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.nif }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.address') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.address }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.zipCode') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.zipCode }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content
                          >{{ $t('global.dateOfRegistration') }}:</v-list-item-content
                        >
                        <v-list-item-content class="align-end">
                          {{
                            moment(item.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>

        <v-col>
          <v-data-iterator
            :items="devicesInfo"
            :items-per-page="1"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  class="ml-4"
                  v-for="item in props.items"
                  :key="item.firstName"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="11"
                >
                  <v-card>
                    <v-card-title class="subheading font-weight-bold">
                      {{ $t('titles.deviceInformation') }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content
                          >{{ $t('global.numberOfDevices') }}:</v-list-item-content
                        >
                        <v-list-item-content class="align-end">
                          {{ item.devices }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.version') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.version }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content
                          >{{ $t('global.cardsAvailable') }}:</v-list-item-content
                        >
                        <v-list-item-content class="align-end">
                          {{ item.cards }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.sampleRate') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.sampleRate }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.lastReading') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.down }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.active') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.down }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>{{ $t('global.down') }}:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.active }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-container>

    <!--GROUP SERVICES-->

    <div class="align-center text-center mt-6 mx-6">
      <v-card elevation="4" style="margin: auto">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t('tables.subscribedServices') }}</v-toolbar-title>
        </v-toolbar>
        <div class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table v-if="$i18n.locale == 'en'"
            :headers="headers"
            :items="userServices"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.cards8`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.cards8">done</v-icon>
                <v-icon color="red darken-2" v-if="!item.cards8">close</v-icon>
              </td>
            </template>
            <template v-slot:[`item.cards12`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.cards12">done</v-icon>
                <v-icon color="red darken-2" v-if="!item.cards12">close</v-icon>
              </td>
            </template>
            <template v-slot:[`item.limitedPortal`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.limitedPortal"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.limitedPortal"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.advPortal`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.advPortal"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.advPortal"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.csvExport`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.csvExport"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.csvExport"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.analytics`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.analytics"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.analytics"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.control`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.control">done</v-icon>
                <v-icon color="red darken-2" v-if="!item.control">close</v-icon>
              </td>
            </template>
            <template v-slot:[`item.extIntegration`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.extIntegration"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.extIntegration"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.notification`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.notification"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.notification"
                  >close</v-icon
                >
              </td>
            </template>
          </v-data-table>

          <v-data-table v-if="$i18n.locale == 'pt'"
            :headers="headersPt"
            :items="userServices"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.cards8`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.cards8">done</v-icon>
                <v-icon color="red darken-2" v-if="!item.cards8">close</v-icon>
              </td>
            </template>
            <template v-slot:[`item.cards12`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.cards12">done</v-icon>
                <v-icon color="red darken-2" v-if="!item.cards12">close</v-icon>
              </td>
            </template>
            <template v-slot:[`item.limitedPortal`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.limitedPortal"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.limitedPortal"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.advPortal`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.advPortal"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.advPortal"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.csvExport`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.csvExport"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.csvExport"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.analytics`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.analytics"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.analytics"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.control`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.control">done</v-icon>
                <v-icon color="red darken-2" v-if="!item.control">close</v-icon>
              </td>
            </template>
            <template v-slot:[`item.extIntegration`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.extIntegration"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.extIntegration"
                  >close</v-icon
                >
              </td>
            </template>
            <template v-slot:[`item.notification`]="{ item }">
              <td class="d-flex align-center">
                <v-icon color="green darken-2" v-if="item.notification"
                  >done</v-icon
                >
                <v-icon color="red darken-2" v-if="!item.notification"
                  >close</v-icon
                >
              </td>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import HelperMethods from "@/utilities/HelperMethods"
export default {
  data() {
    return {
      userServices: [],
      headers: [
        { text: "8 Cards", value: "cards8" },
        { text: "12 Cards", value: "cards12" },
        { text: "Limited Portal", value: "limitedPortal" },
        { text: "Advanced Portal", value: "advPortal" },
        { text: ".csv Export", value: "csvExport" },
        { text: "Analytics", value: "analytics" },
        { text: "Control", value: "control" },
        { text: "External Integration", value: "extIntegration" },
        { text: "Notification", value: "notification" },
      ],
      headersPt: [
        { text: "8 Cartas", value: "cards8" },
        { text: "12 Cartas", value: "cards12" },
        { text: "Portal Limitado", value: "limitedPortal" },
        { text: "Portal Avançado", value: "advPortal" },
        { text: "Exporte para .csv", value: "csvExport" },
        { text: "Análise Dados", value: "analytics" },
        { text: "Controlo", value: "control" },
        { text: "Integração Externa", value: "extIntegration" },
        { text: "Notificações", value: "notification" },
      ],
      userInfo: [],
      groupInfo: [{}],
      devicesInfo: [{}],
      extras: [],
      group: [],
      user: "",
      numberOfUsers: "",
      loading: false,
    };
  },
  async mounted() {

    this.userServices.push(this.$store.getters.userServices);
    this.userInfo.push(this.$store.getters.userInfo);
    this.group.push(this.$store.getters.groupInfo);
    this.extras = this.$store.getters.extras;

    this.groupInfo[0].name = this.group[0].name;
    this.groupInfo[0].subscription = this.group[0].refPackage;
    this.groupInfo[0].fee = this.userServices[0].feeBasic;
    this.groupInfo[0].users = this.group[0].users;
    this.groupInfo[0].nif = this.userInfo[0].nif;
    this.groupInfo[0].address = this.userInfo[0].address;
    this.groupInfo[0].zipCode = this.userInfo[0].zipCode;
    this.groupInfo[0].createdAt = this.group[0].createdAt;

    this.devicesInfo[0].devices = this.$store.getters.devices.length;
    this.devicesInfo[0].version = "v1.01";
    if (this.$store.getters.userServices.sr5) {
      this.devicesInfo[0].sampleRate = "5 min";
    } else if (this.$store.getters.userServices.sr10) {
      this.devicesInfo[0].sampleRate = "10 min";
    } else if (this.$store.getters.userServices.sr20) {
      this.devicesInfo[0].sampleRate = "20 min";
    } else this.devicesInfo[0].sampleRate = "N/A";

    if (this.$store.getters.userServices.cards12) {
      this.devicesInfo[0].cards = "12";
    } else if (
      this.$store.getters.userServices.cards8 &&
      !this.$store.getters.userServices.cards12
    ) {
      this.devicesInfo[0].cards = "8";
    } else this.devicesInfo[0].cards = "N/A";

    this.groupInfo.forEach(function (value) {
      value.packageName = HelperMethods.namePackage(value["subscription"]);
    });
  },
};
</script>

<style scoped></style>
